.Quizbox {
    width: 250px;
    margin: 50px auto;
    padding: 70px;
    border-radius: 40px;
    box-shadow:  11px 11px 0px #b2bcd3,
             -11px -11px 0px #faffff;
}

h2 {
    background-color: white;
    padding: 10px 20px;
    border-radius: 12px;
    border: solid 1px #b2bcd3;
    font-family: 'Courier New', Courier, monospace;
}

h3 {
    border-radius: 12px;
    border: solid 1px #b2bcd3;
    padding: 2cqmax 0;
}

input{
    text-align:center;
 }

 button {
    border-radius: 10px;
    background: none;
    box-shadow:  5px 5px 0 #b2bcd3,
             -5px -5px 0 #faffff;
    padding: 10px 20px;
    border: none;
    transition: .1s ease box-shadow;
 }

 button:active {
    box-shadow:  -5px -5px 0 #b2bcd3,
             5px 5px 0 #faffff;
 }

 .toggleClosed {
    background-color: #d3d7e1;
    background-image: url(../img/backgr-x.png);
    background-position: center;
 }
