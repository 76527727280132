.App {
  text-align: center;
}

#nextBtn {
  display: none;
  margin: 0 auto;
}

.startBtn {
  margin: 0 auto;
}

#reviewResults {
  display: none;
  margin: 40px auto 0;

}