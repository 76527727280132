.Progress {
    padding: 0;
    border-radius: 12px;
    width: 90%;
    margin: 100px auto 0;
    box-shadow:  6px 6px 0px #b2bcd3,
    -6px -6px 0px #faffff;
}

.progItemBox {
    display: flex;
    justify-content: space-around;
}

.progressItemCheckIndex {
    width: 15px;
    height: 15px;
    border: solid 1px #b2bcd3;
    margin: 20px 0;
    border-radius: 15px;
}

.resultBox {
    display: none;
}

.resultItem {
    padding: 5px 0;
    margin: 10px 0;
}